import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
  Button,
} from '@airbus/components-react';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { AlertBarProp, useAlertBarContextApi } from '../context/AlertBarContext';
import { useLanguageContextAPI } from '../context/LanguageContext';

type DisclaimerComponentProps = {
  disclaimerOpen: boolean;
  updateDisclaimerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateAcceptDisclaimer: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomDialog = styled(Dialog)({
  maxWidth: '100%',
});

export default function DisclaimerComponent(props: DisclaimerComponentProps) {
  const { disclaimerOpen, updateDisclaimerOpen, updateAcceptDisclaimer } = props;

  const { updateAlertBarState }: { updateAlertBarState: (state: AlertBarProp | null) => void } =
    useAlertBarContextApi();
  const { t } = useLanguageContextAPI();

  const onAcceptHandler = async () => {
    updateDisclaimerOpen(false);
    updateAcceptDisclaimer(true);

    const success = await (await fetch('/api/disclaimerAccept')).json();
    if (success?.errors)
      updateAlertBarState({ children: t('error.error_disclaimer'), severity: 'error' });
  };

  return (
    <div>
      <CustomDialog
        fullScreen
        open={disclaimerOpen}
        onClose={() => updateDisclaimerOpen(false)}
        disableBackdropClick
        disableEscapeKeyDown
        disablePortal
      >
        <DialogHeader>Disclaimer for Data Policy</DialogHeader>
        <DialogContent>
          <DisclaimerDocumentExport pdf />
        </DialogContent>
        <DialogActions>
          <Button variant='secondary' onClick={() => updateDisclaimerOpen(false)}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onAcceptHandler}>
            Accept
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
}

// HTML contents of Disclaimer Document Exported from Google Sheets
function DisclaimerDocumentExport(props) {
  const styles: { container: CSSProperties; pdfObject: CSSProperties; responsive: string } = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'scroll',
      width: '100%',
    },
    pdfObject: {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '90%',
      height: '90%',
    },
    responsive: `
      .ds-dialog6-content {
        display: flex;
        justify-content: center;
      }
        .pdf-container {
          width: 20%;
          height: 35rem;
          flex-direction: column;
          // background-color: black;
        }
      @media (max-width: 1024px) { /* lg */
        .pdf-container {
          height: 19rem;
          // background-color: red;
        }
      }
      @media (max-width: 640px) { /* sm */
        .pdf-container {
          height: 20rem;
          // background-color: green;
        }
      }
      @media (max-width: 475px) { /* sm */
        .pdf-container {
          height: 100%;
          // background-color: cyan;
        }
      }
    `,
  };
  if ('html' in props)
    return (
      <>
        <p>
          <a href='/MBD CP V2 Data Privacy Statement.pdf' target='_blank'>
            View Privacy Statement
          </a>
        </p>
        <div
          style={{
            overflowY: 'scroll',
            height: '20rem',
            // '@media (max-width: 900px)': {
            //   height: '5rem',
            // },
          }}
        >
          <p style={{ textAlign: 'center', lineHeight: 1.15 }}>
            <span style={{ fontSize: '12pt' }}>Welcome to the </span>
            <br />
            <span style={{ fontSize: '16pt' }}>MBD Collaboration Platform V2 </span>
            <br />
            <span style={{ fontSize: '12pt' }}> for Airbus Aerostructures and Premium AEROTEC</span>
          </p>
          <div style={{ width: '23rem' }}>
            <p
              style={{
                paddingTop: '12pt',
                paddingBottom: '12pt',
                paddingLeft: '12pt',
                lineHeight: 1.15,
                textAlign: 'left',
              }}
            >
              <span>
                You must comply with the following statements to continue using the application PLM:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  Through this platform, you are accessing Airbus, supplier, or third-party data.
                  You may have been granted some access to classified data. It is your
                  responsibility to use them accordingly in the frame of your professional activity.
                </span>
              </li>
              <li>
                <span>It is forbidden to enter, upload, or download:</span>
                <ul>
                  <li>Sensitive personal data</li>
                  <li>Airbus Red data</li>
                  <li>
                    National Security Restricted data or National Security Higher than restricted
                    data
                  </li>
                  <li>Private data</li>
                  <li>Export Controlled data</li>
                  <li>Military data</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <span>It is forbidden to:</span>
                <ul>
                  <li>
                    Circumvent or interfere with any of the platform&apos;s authentication or
                    security measures
                  </li>
                  <li>
                    Decompile, disassemble, scan, reverse engineer, or discover any underlying ideas
                    or algorithms of any software contained in the platform except what is covered
                    by Open Source software licenses or (for Airbus internal employees) what is
                    covered and permitted by the applicable Airbus inner source licenses.
                  </li>
                  <li>
                    Discover any source code of any component of the platform except what is covered
                    by Open Source software licenses or (for Airbus internal employees) what is
                    covered and permitted by the applicable Airbus inner source licenses.
                  </li>
                  <li>Perform massive download of data</li>
                  <li>
                    Interact with the software through automated processes (e.g., Robotic Process
                    Automation (RPA)) without written approval from the Application Manager.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <span>For more information on Data Classification, please check our </span>
              <a
                href='https://sites.google.com/airbus.com/data-governance-airbus-commerc/resources/data-tagging-in-gdrive-for-ai'
                style={{ color: '#1155cc', textDecoration: 'underline' }}
              >
                Data Governance Site
              </a>
              <span> (for suppliers: refer to your nominated Purchaser contact).</span>
            </p>
            <p>
              <span>
                In case of doubt on risk associated with the data, please reach out to the{' '}
              </span>
              <a
                href='https://myportfolio.eu.airbus.corp/application-versions/APP-54493'
                style={{ color: '#1155cc', textDecoration: 'underline' }}
              >
                Business Owner
              </a>
              <span> of the application (Airbus internal employees only) or </span>
              <a
                href='mailto:datagovernance.asa@airbus.com'
                style={{ color: '#1155cc', textDecoration: 'underline' }}
              >
                datagovernance.asa@airbus.com
              </a>
              .
            </p>
          </div>
        </div>
      </>
    );
  else if ('pdf' in props) {
    return (
      <>
        <style>{styles.responsive}</style>
        <div className='pdf-container' style={styles.container}>
          <p>
            <a href={'/MBD CP V2 Disclaimer.pdf' + '#zoom=150%'} target='_blank' rel='noreferrer'>
              Open in new tab
            </a>
          </p>
          <iframe
            // type='application/pdf'
            src={'/MBD CP V2 Disclaimer.pdf' + '#view=FitH'}
            width='100%'
            height='100%'
            style={styles.pdfObject}
          >
            <p>
              Unable to display PDF file.{' '}
              <a
                href={'/MBD CP V2 Data Privacy Statement.pdf' + '#zoom=150%'}
                target='_blank'
                rel='noreferrer'
              >
                View in a new tab
              </a>{' '}
              instead.
            </p>
          </iframe>
        </div>
      </>
    );
  }
}
