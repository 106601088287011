import { createContext, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Banner } from '@airbus/components-react';

export const AlertBarContext = createContext(null);

export type AlertBarProp = {
  children?: ReactElement | string;
  severity: 'success' | 'warning' | 'error';
  stopAutoClose?: boolean;
} | null;

export const AlertBarContextProvider = ({ children }) => {
  const [alertBar, setAlertBar] = useState<AlertBarProp>(null);

  const AlertBar = () => {
    useEffect(() => {
      if (alertBar && !alertBar?.stopAutoClose) {
        setTimeout(() => {
          setAlertBar(null);
        }, 6000);
      }
      const alertBarDOM = document.getElementById('alertBarBanner');
      if (alertBarDOM) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }, [alertBar]);

    return alertBar ? (
      <Banner
        variant={alertBar?.severity}
        style={{ marginBottom: 2 }}
        onClose={() => setAlertBar(null)}
        id='alertBarBanner'
      >
        {alertBar?.children}
      </Banner>
    ) : null;
  };

  const updateAlertBarState = (state: AlertBarProp | null) => {
    setAlertBar(state);
  };

  const value = useMemo(() => ({ alertBar, updateAlertBarState }), [alertBar, updateAlertBarState]);

  return (
    <>
      <AlertBarContext.Provider value={value}>
        <AlertBar />
        {children}
      </AlertBarContext.Provider>
    </>
  );
};
export default AlertBarContextProvider;

export function useAlertBarContextApi() {
  const context = useContext(AlertBarContext);
  if (!context) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
